<template>
    <Navbar page-name="Configurações" />
    <h3 class="text-center mt-4 mb-4">Configurações</h3>
    <div class="container">
        <div class="row justify-content-center">
            <div class="card m-2" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">Métodos de Pagamento</h5>
                    <p class="card-text">Cadastrar métodos e formas de pagamento para serem utilizados em cadastro de pagamentos.</p>
                    <router-link to="/payment-methods" class="btn btn-primary">Gerenciar</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'

export default {
  components: {
    Navbar
  }
}
</script>