<template>
  <Navbar page-name="Pagamentos" />
  <h3 class="text-center mt-4 mb-4">Adicionar Pagamento</h3>
  <div class="border border-secondary rounded p-4" style="width: 500px; margin: auto;">
    <form @submit.prevent="addPayment">
        <div class="mb-3">
            <label for="os" class="form-label">O.S.</label>
            <input type="text" class="form-control" id="os" v-model="payment.os" />
        </div>
        <div class="mb-3">
            <label for="forma" class="form-label">Forma de Pagamento</label>
            <select class="form-select" id="forma" v-model="payment.forma" required>
                <option selected>Selecione</option>
                <option v-for="method in paymentMethods" :key="method.id" :value="method.id">{{method.nome}} ({{method.parcelas}}x) - {{method.prazo}} dia(s)</option>
            </select>
        </div>
        <div class="mb-3">
            <label for="data" class="form-label">Data de Pagamento</label>
            <input class="form-control" type="date" id="data" v-model="payment.data" required />
        </div>
        <label for="valor" class="form-label">Valor</label>
        <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon2">R$</span>
            <input class="form-control" type="number" min="0.01" step="0.01" id="valor" v-model="payment.valor" required/>
        </div>
        <div class="mb-3">
            <label for="referencia" class="form-label">Referência</label>
            <input type="text" id="referencia" class="form-control" v-model="payment.referencia" />
        </div>
        <div class="d-grid gap-2">
            <button type="submit" class="btn btn-secondary">Adicionar</button>
        </div>
    </form>
  </div>
  <div class="text-center mt-3"><router-link class="btn btn-primary" to="/payments" role="button">Voltar</router-link></div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import moment from 'moment'
import { getFirestore, collection, getDocs, query, orderBy, addDoc, writeBatch, doc } from 'firebase/firestore'

export default {
  components: {
    Navbar
  },
  data() {
    return {
      paymentMethods: [],
      payMethMap: {},
      payment: {
          os: '',
          forma: '',
          data: moment(Date()).format('YYYY-MM-DD'),
          valor: '',
          referencia: ''
      },
      paymentId: ''
    }
  },
  created() {
    const db = getFirestore();
    const q = query(collection(db, 'payment-methods'), orderBy('nome'), orderBy('parcelas', 'asc'));
    getDocs(q)
    .then(docs => {
      docs.forEach(doc => {
        let item = doc.data();
        item.id = doc.id;
        this.paymentMethods.push(item);
        this.payMethMap[doc.id] = doc.data();
      });
    })
    .catch(err => {
      console.log(err);
    });
  },
  methods: {
      addPayment() {
          const db = getFirestore();
          addDoc(collection(db, 'payments'),{
              os: this.payment.os,
              forma: this.payMethMap[this.payment.forma].nome,
              parcelas: this.payMethMap[this.payment.forma].parcelas,
              prazo: this.payMethMap[this.payment.forma].prazo,
              taxa: this.payMethMap[this.payment.forma].taxa,
              data: this.payment.data,
              valor: this.payment.valor,
              referencia: this.payment.referencia
          })
          .then((docRef) => {
              this.addReceipts(docRef.id);
              this.paymentId = docRef.id;
          })
          .catch(err => {
              console.log(err);
          });
      },
      addReceipts(payId) {
          const db = getFirestore();
          const batch = writeBatch(db);
          let valorTotal = this.payment.valor;
          let parcelas = this.payMethMap[this.payment.forma].parcelas;
          let valorParcela = Number.parseFloat(valorTotal/parcelas).toFixed(2);
          let taxa = this.payMethMap[this.payment.forma].taxa;
          let prazo = this.payMethMap[this.payment.forma].prazo;
          for (let i = 1; i <= parcelas; i++) {
              let prazoAtual = i * prazo;
              let vencimentoAtual = moment(new Date(this.payment.data)).add(prazoAtual + 1, 'd');
              if (moment(vencimentoAtual).weekday() == 5) vencimentoAtual = moment(vencimentoAtual).add(3, 'days');
              else if (moment(vencimentoAtual).weekday() == 6) vencimentoAtual = moment(vencimentoAtual).add(2, 'days');
              var recRef = doc(collection(db, 'receipts'));
              let bruto = (i == parcelas) ? Number.parseFloat(valorTotal - ((i - 1) * valorParcela)).toFixed(2) : valorParcela;
              batch.set(recRef,{
                  pagamento: payId,
                  vencimento: moment(vencimentoAtual).format('YYYY-MM-DD'),
                  forma: this.payMethMap[this.payment.forma].nome,
                  taxa: taxa,
                  valorBruto: bruto,
                  valorLiquido: Number.parseFloat(bruto - Number.parseFloat((bruto * (taxa/100))).toFixed(2)).toFixed(2),
                  os: this.payment.os,
                  referencia: this.payment.referencia,
                  parcela: i
              });
          }
          batch.commit()
          .then(() => {
              alert('Pagamento adicionado!');
              this.payment.os = '';
              this.payment.forma = '';
              this.payment.data = moment(Date()).format('YYYY-MM-DD');
              this.payment.valor = '';
              this.payment.referencia = '';
              this.$router.push('/view-payment/' + this.paymentId);
          })
          .catch(err => {
              console.log(err);
          });
      }
  }
}
</script>