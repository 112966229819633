<template>
    <Navbar page-name="Configurações" />
    <h3 class="text-center mt-4 mb-4">Métodos de Pagamento</h3>
    <div style="width: 75%; margin: 0 auto;">
        <div class="text-end"><router-link class="btn btn-primary" to="/new-payment-method" role="button">Adicionar</router-link></div>
        <table v-if="paymentMethods.length > 0" class="table table-striped table-hover">
            <thead>
                <tr>
                <th scope="col" style="padding-left: 1em;">Nome</th>
                <th scope="col" style="padding-left: 1em;">Tipo</th>
                <th scope="col" style="padding-left: 1em;">Parcelas</th>
                <th scope="col" style="padding-left: 1em;">Prazo</th>
                <th scope="col" style="padding-left: 1em;">Taxa</th>
                <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in paymentMethods" :key="item.id">
                    <th scope="row" style="padding: 1em;">{{item.nome}}</th>
                    <td style="padding: 1em;">{{item.tipo}}</td>
                    <td style="padding: 1em;">{{item.parcelas}}x</td>
                    <td style="padding: 1em;">{{item.prazo}} dias</td>
                    <td style="padding: 1em;">{{item.taxa}}%</td>
                    <td style="padding: .75em;">
                        <button type="button" class="btn btn-primary btn-sm" style="margin-right: .5em;" :name="item.id" @click="toggleEditModal">
                            <img src='@/assets/edit.svg' style="width: 1.2em; margin-bottom: .2em;" :name="item.id"/>
                        </button>
                        <button type="button" class="btn btn-danger btn-sm" :name="item.id" @click="deletar">
                            <img src='@/assets/trash.svg' style="width: 1.2em; margin-bottom: .2em;" :name="item.id" />
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-else class="text-center mt-3">Não existem métodos cadastrados ainda.</div>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import { getFirestore, collection, getDocs, query, orderBy, doc, deleteDoc } from "firebase/firestore"

export default {
  components: {
    Navbar
  },
  data() {
      return {
          paymentMethods: [],
      }
  },
  methods: {
      toggleEditModal(event) {
          this.$router.push(`/edit-payment-method/${event.target.name}`);
      },
      deletar(event) {
          if (confirm('Deseja deletar este método?')) {
              const db = getFirestore();
              const metodo = doc(db, 'payment-methods', event.target.name);
              deleteDoc(metodo)
              .then(() => {
                  alert('Deletado com sucesso!');
                  this.loadMethods();
              })
              .catch(err => {
                  console.log(err);
              });
          }
      },
      loadMethods() {
        this.paymentMethods = [];
        const db = getFirestore();
        const q = query(collection(db, 'payment-methods'), orderBy('nome'), orderBy('parcelas'));
        getDocs(q).then( res => {
            res.forEach(doc => {
                let m = doc.data();
                m.id = doc.id;
                this.paymentMethods.push(m);
            })
        })
        .catch(err => {
            console.logf(err);
        });
      }
  },
  created() {
    this.loadMethods();
  }
}
</script>