<template>
    <div class="vertical-center  bg-secondary bg-gradient">
        <div class="border border-secondary rounded p-4 bg-light shadow" style="width: 400px; margin: auto;">
            <h2 class="text-center mb-2">Login</h2>
            <form @submit.prevent="doLogin">
                <div class="mb-3">
                    <label for="email" class="form-label">Email</label>
                    <input type="email" class="form-control" id="email" placeholder="Entre com seu email" v-model="email" />
                </div>
                <div class="mb-3">
                    <label for="password" class="form-label">Senha</label>
                    <input type="password" class="form-control" id="password" placeholder="Entre com sua senha" v-model="password" />
                </div>
                <div class="form-text mb-3 text-end">
                    <router-link to="/forgot" class="link-secondary">Esqueci minha senha</router-link>
                </div>
                <div class="d-grid gap-2">
                    <button type="submit" class="btn btn-secondary">Entrar</button>
                </div>
            </form>
        </div> 
    </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

export default {
    data() {
        return {
            email: '',
            password: ''
        }
    },
    methods: {
        doLogin() {
            const auth = getAuth();
            signInWithEmailAndPassword(auth, this.email, this.password)
            .then(user => {
                if (user) window.location.href = "/";
            })
            .catch(err => {
                alert('Não foi possível logar.\n' + err.message);
            });
        }
    }
}
</script>

<style scoped>
.vertical-center {
    display:flex;
    align-items: center;
    height: 100vh;
}
</style>