<template>
  <Navbar page-name="Pagamentos" />
  <h3 class="text-center mt-4 mb-4">Pagamentos</h3>
    <div style="width: 75%; margin: 0 auto;">
      <div class="container mb-3">
        <div class="row">
          <div class="col">
            <label for="a-partir" class="col-form-label me-2">A partir de</label>
            <input type="date" v-model="today" id="a-partir" @change="loadPayments"/>
          </div>
          <div class="col">
            <div class="text-end"><router-link class="btn btn-primary" to="/new-payment" role="button">Adicionar Pagamento</router-link></div>
          </div>
        </div>
      </div>
      <table v-if="payments.length > 0" class="table table-striped table-hover">
            <thead>
                <tr>
                <th scope="col" style="padding-left: 1em;">Data</th>
                <th scope="col" style="padding-left: 1em;">Valor</th>
                <th scope="col" style="padding-left: 1em;">Parcelas</th>
                <th scope="col" style="padding-left: 1em;">Forma</th>
                <th scope="col" style="padding-left: 1em;">OS</th>
                <th scope="col" style="padding-left: 1em;">Referência</th>
                <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in payments" :key="item.id">
                    <th scope="row" style="padding: 1em;">{{item.data}}</th>
                    <td style="padding: 1em;">R${{item.valor}}</td>
                    <td style="padding: 1em;">{{(item.parcelas == 1) ? 'À vista' : item.parcelas + 'x'}}</td>
                    <td style="padding: 1em;">{{item.forma}}</td>
                    <td style="padding: 1em;">{{item.os}}</td>
                    <td style="padding: 1em;">{{item.referencia}}</td>
                    <td style="padding: .75em;" class="text-end">
                      <button type="button" class="btn btn-primary btn-sm" style="margin-right: .5em;" :name="item.id" @click="visualizar">
                            <img src='@/assets/eye.svg' style="width: 1.2em; margin-bottom: .2em;" :name="item.id" />
                        </button>
                        <button type="button" class="btn btn-danger btn-sm" :name="item.id" @click="deletar">
                            <img src='@/assets/trash.svg' style="width: 1.2em; margin-bottom: .2em;" :name="item.id" />
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-else class="text-center mt-3">Não existem pagamentos para esta data.</div>
    </div>
</template>

<script>
import { getFirestore, collection, getDocs, query, orderBy, where, writeBatch, doc } from "firebase/firestore"
import Navbar from '@/components/Navbar.vue'
import moment from 'moment'

export default {
  components: {
    Navbar
  },
  data() {
    return {
      payments: [],
      today: moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'),
    }
  },
  created() {
    this.loadPayments();
  },
  methods: {
    visualizar(event) {
      this.$router.push(`/view-payment/${event.target.name}`);
    },
    loadPayments() {
      this.payments = [];
      const db = getFirestore();
      const q = query(collection(db, 'payments'), where('data', '>=', this.today),  orderBy('data', 'asc'));
      getDocs(q).then( res => {
          res.forEach(doc => {
              let m = doc.data();
              m.id = doc.id;
              this.payments.push(m);
          })
      })
      .catch(err => {
          console.logf(err);
      });
    },
    deletar(event) {
          if (confirm('Deseja deletar este pagamento?')) {
            var pagamento = event.target.name;
            const db = getFirestore();
            const batch = writeBatch(db);
            const q = query(collection(db, 'receipts'), where('pagamento', '==', pagamento));
            getDocs(q).then(res => {
              console.log(res);
              res.forEach(doc => {
                batch.delete(doc.ref);
              });
            })
            .then(() => {
              let docRef = doc(db, 'payments', pagamento);
              batch.delete(docRef);
              batch.commit()
              .then(() => {
                alert('Pagamento removido com sucesso!');
                this.loadPayments();
              })
              .catch(err => {
                console.log(err);
              });
            })
            .catch(err => {
              console.log(err);
            });
          }
      },
  }
}
</script>