<template>
    <div class="vertical-center  bg-secondary bg-gradient">
        <div class="border border-secondary rounded p-4 bg-light shadow" style="width: 400px; margin: auto;">
            <h2 class="text-center mb-2">Nova senha</h2>
            <form @submit.prevent="doReset">
                <div class="mb-3">
                    <label for="email" class="form-label">Email</label>
                    <input type="email" class="form-control" id="email" placeholder="Entre com seu email" v-model="email" />
                </div>
                <div class="form-text mb-3 text-center">
                    Você receberá um email para criar uma nova senha
                </div>
                <div class="d-grid gap-2">
                    <button type="submit" class="btn btn-secondary">Enviar</button>
                </div>
            </form>
        </div> 
    </div>
</template>

<script>
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

export default {
    data() {
        return {
            email: ''
        }
    },
    methods: {
        doReset() {
            const auth = getAuth();
            sendPasswordResetEmail(auth, this.email)
            .then(() => {
                alert('Email de reset de senha enviado!');
            })
            .catch(err => {
                alert('Ocorreu um erro: \n' + err.message);
            });
            console.log(this.email);
        }
    }
}
</script>

<style scoped>
.vertical-center {
    display:flex;
    align-items: center;
    height: 100vh;
}
</style>