import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Forgot from '@/views/Forgot.vue'
import Payments from '@/views/Payments.vue'
import Receipts from '@/views/Receipts.vue'
import Settings from '@/views/Settings.vue'
import PaymentMethods from '@/views/PaymentMethods.vue'
import NewPayment from '@/views/NewPayment.vue'
import ViewPayment from '@/views/ViewPayment.vue'
import NewPaymentMethod from '@/views/NewPaymentMethod.vue'
import EditPaymentMethod from '@/views/EditPaymentMethod.vue'
import { getAuth } from 'firebase/auth'

const routes = [
  {
    path: '/edit-payment-method/:id',
    name: 'Edit',
    component: EditPaymentMethod,
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: '/view-payment/:id',
    name: 'View',
    component: ViewPayment,
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: Forgot
  },
  {
    path: '/payments',
    name: 'Payments',
    component: Payments,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/receipts',
    name: 'Receipts',
    component: Receipts,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/payment-methods',
    name: 'PaymentMethods',
    component: PaymentMethods,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/new-payment-method',
    name: 'NewPaymentMethod',
    component: NewPaymentMethod,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/new-payment',
    name: 'NewPayment',
    component: NewPayment,
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const currentUser = getAuth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) next('login');
  else if (!requiresAuth && currentUser) next('/');
  else next();
});

export default router
