<template>
  <Navbar page-name="Pagamentos" />
  <h3 class="text-center mt-4 mb-4">Detalhes do Pagamento</h3>
  <div style="width: 500px; margin: auto;">
      <h5 class="mb-2 fw-bold">Pagamento</h5>
  </div>
  <div class="border border-secondary rounded p-3" style="width: 500px; margin: auto;">
      <div class="container">
        <div class="row">
            <div class="col">
                <div class="fw-bold">Data</div>
                <div>{{payment.data}}</div>
                <div class="fw-bold mt-3">Valor</div>
                <div>R$ {{payment.valor}}</div>
            </div>
            <div class="col">
                <div class="fw-bold">Forma de Pagamento</div>
                <div>{{payment.forma}}</div>
                <div class="fw-bold mt-3">Parcelas</div>
                <div>{{(payment.parcelas == 1) ? 'À vista' : payment.parcelas + 'x'}}</div>
            </div>
        </div>
        </div>
  </div>
  <div style="width: 500px; margin: auto;" class="mt-4">
      <h5 class="mb-2 fw-bold">Recebimento</h5>
  </div>
  <div class="border border-secondary rounded p-3" style="width: 500px; margin: auto;">
      <table class="table">
          <thead>
              <tr>
                  <th scope="col">Parcela</th>
                  <th scope="col">Data Pagamento</th>
                  <th scope="col">Valor</th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="receipt in receipts" :key="receipt.parcela">
                  <td>{{receipt.parcela}}</td>
                  <td>{{receipt.vencimento}}</td>
                  <td>{{receipt.valorLiquido}}</td>
            </tr>
          </tbody>
      </table>
  </div>
  <div class="text-center mt-3"><router-link class="btn btn-primary" to="/payments" role="button">Voltar</router-link></div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import { getFirestore, doc, getDoc, query, collection, where, getDocs, orderBy} from 'firebase/firestore'

export default {
  components: {
    Navbar
  },
  props: {
      id: String
  },
  methods: {
      loadReceipts(id) {
          const db = getFirestore();
          const q = query(collection(db, 'receipts'), where('pagamento', '==', id), orderBy('parcela'));
          getDocs(q)
          .then(res => {
              res.forEach(doc => {
                  this.receipts.push(doc.data());
              });
              console.log(this.receipts);
          })
          .catch(err => {
              console.log(err);
          });
      }
  },
  data() {
    return {
      payment: {},
      receipts: []
    }
  },
  created() {
    const db = getFirestore();
    const docRef = doc(db, "payments", this.id);
    getDoc(docRef)
    .then(doc => {
      this.payment = doc.data();
      this.loadReceipts(doc.id);
    })
    .catch(err => {
      console.log(err);
    });
  },
}
</script>