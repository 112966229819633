<template>
  <Navbar page-name="Painel" />
  <div class="mt-3 text-center">
    Aqui vai o painel com resumo de pagamentos e recebimentos
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'

export default {
  components: {
    Navbar
  },
  created() {
    this.$router.push('/payments');
  }
}
</script>