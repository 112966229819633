<template>
    <Navbar page-name="Recebimentos" />
    <h3 class="text-center mt-4 mb-4">Recebimentos</h3>
    <div style="width: 75%; margin: 0 auto;">
      <div class="container mb-3 text-center">
            <label for="a-partir" class="col-form-label me-2">A partir de</label>
            <input type="date" v-model="inicio" id="a-partir" @change="loadReceipts"/>
            <label for="ate" class="col-form-label ms-4 me-2">Até</label>
            <input type="date" v-model="fim" id="ate" @change="loadReceipts"/>
      </div>
      <div v-if="recebimentos.length == 0" class="text-center mt-3">Não existem recebimento para as datas selecionadas.</div>
      <div class="border border-secondary rounded p-4 mb-3" v-for="rec in recebimentos" :key="rec.data">
          <h5>{{rec.data}}</h5>
          <table class="table">
              <tbody>
                <tr v-for="pag in rec.pagamentos" :key="pag.pagamento">
                    <td style="width: 40%">{{pag.forma}}</td>
                    <td style="width: 25%">Parcela {{pag.parcela}}</td>
                    <td style="width: 25%">R$ {{pag.valorLiquido}}</td>
                    <td style="width: 10%" class="text-end">
                        <button type="button" class="btn btn-primary btn-sm" style="margin-right: .5em;" :name="pag.pagamento" @click="visualizar">
                            <img src='@/assets/eye.svg' style="width: 1.2em; margin-bottom: .2em;" :name="pag.pagamento" />
                        </button>
                    </td>
                </tr>
              </tbody>
          </table>
          <h5 class="text-end">R$ {{rec.total}}</h5>
      </div>    
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import moment from 'moment'
import { getFirestore, query, collection, where, getDocs } from 'firebase/firestore'

export default {
  components: {
    Navbar
  },
  data() {
      return {
          inicio: moment(new Date()).format('YYYY-MM-DD'),
          fim: moment(new Date()).add(30, 'd').format('YYYY-MM-DD'),
          recebimentos: []
      }
  },
  created() {
      this.loadReceipts();
  },
  methods: {
      visualizar(event) {
      this.$router.push(`/view-payment/${event.target.name}`);
    },
      loadReceipts() {
          this.recebimentos = [];
          var receipts = [];
          var perDay = {};
          const db = getFirestore();
          const q = query(collection(db, 'receipts'), where('vencimento', '>=', this.inicio),  where('vencimento', '<=', this.fim));
          getDocs(q)
          .then(docs => {
              docs.forEach(doc => {
                  receipts.push(doc.data());
              });
              console.log(receipts)
              receipts.forEach(r => {
                  let ent = perDay[r.vencimento];
                  if (ent) ent.push(r);
                  else ent = [r];
                  perDay[r.vencimento] = ent;
              });
              var keys = Object.keys(perDay);
              keys.forEach(key => {
                  let res = {};
                  let total = 0;
                  let pagamentos = perDay[key];
                  pagamentos.forEach(pag => {
                      total = total + pag.valorLiquido;
                  });
                  res.data = key;
                  res.total = Number.parseFloat(total).toFixed(2);
                  res.pagamentos = pagamentos;
                  this.recebimentos.push(res);
              });
              console.log(this.recebimentos);
          })
          .catch(e => {
              console.log(e);
          });
      }
  }
}
</script>