<template>
    <Navbar page-name="Configurações" />
    <h3 class="text-center mt-4 mb-4">Novo Método de Pagamento</h3>
    <div class="border border-secondary rounded p-4" style="width: 500px; margin: auto;">
        <form @submit.prevent="addPayment">
            <div class="mb-3">
                <label for="tipo" class="form-label">Tipo</label>
                <select class="form-select" id="tipo" v-model="tipo" required>
                    <option selected>Selecione</option>
                    <option value="Cartão de Crédito">Cartão de Crédito</option>
                    <option value="Cartão de Débito">Cartão de Débito</option>
                    <option value="Boleto">Boleto</option>
                    <option value="Depósito/PIX">Depósito/PIX</option>
                    <option value="Outro">Outro</option>
                </select>
            </div>
            <div class="mb-3" v-if="(tipo == 'Cartão de Crédito')">
                <label for="bandeira" class="form-label">Bandeira</label>
                <select class="form-select" id="bandeira" v-model="bandeira" required>
                    <option selected>Selecione</option>
                    <option value="American Express">American Express</option>
                    <option value="Elo">Elo</option>
                    <option value="Hipercard">Hipercard</option>
                    <option value="Mastercard">Mastercard</option>
                    <option value="Visa">Visa</option>
                </select>
            </div>
            <div class="mb-3" v-if="(tipo == 'Cartão de Débito')">
                <label for="bandeira" class="form-label">Bandeira</label>
                <select class="form-select" id="bandeira" v-model="bandeira" required>
                    <option selected>Selecione</option>
                    <option value="Elo Débito">Elo Débito</option>
                    <option value="Mastercard Débito">Mastercard Débito</option>
                    <option value="Visa Débito">Visa Débito</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="parcelas" class="form-label">Parcelas</label>
                <select class="form-select" id="parcelas" v-model="parcelas" required>
                    <option selected>Selecione</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                </select>
            </div>
            <label for="prazo" class="form-label">Prazo</label>
            <div class="input-group mb-4">
                <input type="number" class="form-control" id="prazo" placeholder="Digite o prazo em dias" v-model="prazo" required/>
                <span class="input-group-text" id="basic-addon2">dias</span>
            </div>
            <label for="taxa" class="form-label">Taxa</label>
            <div class="input-group mb-4">
                <input type="number" min="0" max="10" step="0.01" class="form-control" id="taxa" placeholder="Digite a taxa em porcentagem" v-model="taxa" required/>
                <span class="input-group-text" id="basic-addon2">%</span>
            </div>
            <div class="d-grid gap-2">
                <button type="submit" class="btn btn-secondary">Adicionar</button>
            </div>
        </form>
    </div>
    <div class="text-center mt-3"><router-link class="btn btn-primary" to="/payment-methods" role="button">Voltar</router-link></div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import { getFirestore, addDoc, collection } from "firebase/firestore"

export default {
  components: {
    Navbar
  },
  data() {
      return {
          bandeira: '',
          tipo: '',
          parcelas: '',
          prazo: '',
          taxa: ''
      }
  },
  methods: {
      addPayment() {
        const db = getFirestore();
        let nome;
        if ((this.tipo == 'Cartão de Crédito') || (this.tipo == 'Cartão de Débito')) nome = this.bandeira;
        else nome = this.tipo;
        addDoc(collection(db, "payment-methods"), {
            nome: nome,
            parcelas: this.parcelas,
            prazo: this.prazo,
            tipo: this.tipo,
            taxa: this.taxa,
            createdAt: new Date()
        })
        .then(() => {
            alert('Método adicionado com sucesso!');
            this.bandeira = '';
            this.tipo = '';
            this.parcelas = '';
            this.prazo = '';
            this.taxa = '';
        })
        .catch(err => {
            alert('Ocorreu um erro:\n' + err.message);
        });

      }
  }
}
</script>