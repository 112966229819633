<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light" style="padding: 0.5rem 4rem;">
    <div class="container-fluid">
        <a class="navbar-brand" href="#">Financeiro JJ</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
                <!--<li class="nav-item">
                    <router-link :class="pageName == 'Painel' ? 'nav-link active' : 'nav-link'" to="/">Painel</router-link>
                </li>-->
                <li class="nav-item">
                    <router-link :class="pageName == 'Pagamentos' ? 'nav-link active' : 'nav-link'" to="/payments">Pagamentos</router-link>
                </li>
                <li class="nav-item">
                    <router-link :class="pageName == 'Recebimentos' ? 'nav-link active' : 'nav-link'" to="/receipts">Recebimentos</router-link>
                </li>
                <li class="nav-item">
                    <router-link :class="pageName == 'Configurações' ? 'nav-link active' : 'nav-link'" to="/settings">Configurações</router-link>
                </li>
            </ul>
        </div>
        <form class="d-flex">
            <span class="navbar-text me-3">
                {{userEmail}}
            </span>
            <button class="btn btn-sm btn-secondary" type="button" @click="sair">Sair</button> 
        </form>
    </div>
</nav>
</template>

<script>
import { getAuth, signOut } from 'firebase/auth';

export default {
    name: 'Navbar',
    data() {
        return {
            userEmail: ''
        }
    },
    props: {
        pageName: String
    },
    created() {
        const user = getAuth().currentUser.email;
        this.userEmail = user;
    },
    methods: {
        sair() {
            const auth = getAuth();
            signOut(auth)
            .then(() => {
                window.location.href = '/';
            })
            .catch(err => {
                console.log(err.message);
            });
        }
    }
}
</script>