import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from '@firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyA3o3vVfwzjeW7Cohn1OC_hQoFg0KBgDks",
    authDomain: "jj-center-car.firebaseapp.com",
    projectId: "jj-center-car",
    storageBucket: "jj-center-car.appspot.com",
    messagingSenderId: "30672750811",
    appId: "1:30672750811:web:34ac76c182c308ee8e24fe"
};

initializeApp(firebaseConfig);

let app;
const auth = getAuth();
onAuthStateChanged(auth, (user) => {
    if (!app) {
        console.log(user);
        createApp(App).use(router).mount('#app');
    }
});